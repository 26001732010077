<template>
    <v-card tile>
        <v-layout d-flex :column="!$vuetify.breakpoint.mdAndUp">
            <v-layout d-flex justify-center class="mx-2" style="min-width: 10em; max-width: 10em;">
              <v-img v-if="getItem.img.length" :src="getItem.img[0]" @click="onClick(0)" min-width="10em" min-height="10em" max-width="10em" max-height="10em" contain/>
              <vue-gallery-slideshow v-if="getItem.img.length" :images="getItem.img" :index="index" @close="index = null"></vue-gallery-slideshow>
                <v-icon v-if="!getItem.img.length" size="10em">
                    mdi-image-off
                </v-icon>
            </v-layout>
            <v-layout d-flex column>
                <v-layout d-flex align-center style="max-height: 2.25em;">
                    <v-btn icon dark @click="$emit('select')" color="primary">
                        <v-icon>
                            mdi-selection-search
                        </v-icon>
                    </v-btn>
                    <div class="headline">{{artikelNummer}}</div>
                    <v-spacer/>

                </v-layout>
                <v-layout column class="pl-3">
                    <div class="headline">{{getItem.brand.name}}</div>
                    <div>{{getItem.data.name}}</div>
                    <div class="red--text">{{getItem.data.extratext}}</div>
                  <div></div>
                </v-layout>
                <div v-if="open">
                    <div v-if="getItem.attributes.length">
                        <div class="heading mt-3">{{$t('article.attributes')}}</div>
                        <v-simple-table dense>
                            <tbody>
                            <tr v-for="(attr, key) in getItem.attributes" :key="key">
                                <th style="width: 25%">{{attr.name}}</th>
                                <td>{{attr.value}}</td>
                            </tr>
                            </tbody>
                        </v-simple-table>
                    </div>
                    <div v-if="getItem.prices.length">
                        <div class="heading mt-3">{{$t('article.prices')}}</div>
                        <v-simple-table dense>
                            <thead>
                            <tr>
                                <th>{{$t('article.price.amount')}}</th>
                                <th>{{$t('article.price.price')}}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(price, key) in getItem.prices" :key="key">
                                <td style="width: 10%;">{{$t('article.price.from')}} {{price.amount}}</td>
                                <td>{{price.price | currency}} <span class="text--secondary">{{$t('article.price.perpiece')}}</span></td>
                            </tr>
                            </tbody>
                        </v-simple-table>
                    </div>
                </div>
                <v-layout d-flex justify-center>
                    <v-btn @click="open = !open" color="primary" icon>
                        <v-icon v-show="open" size="30">
                          mdi-arrow-up-bold-circle
                        </v-icon>
                        <v-icon v-show="!open" size="30">
                            mdi-arrow-down-bold-circle
                        </v-icon>
                    </v-btn>
                </v-layout>

                <v-layout d-flex align-center style="max-height: 2.25em;">
                    <v-icon v-if="getItem.prices.length > 1" class="mr-1" large color="green darken-2" @click="open = true">
                        mdi-cash-multiple
                    </v-icon>
                    <span v-if="getItem.prices.length > 1" class="text--secondary caption" @click="open = true">
                      {{$t('article.price.graduated')}}
                    </span>
                    <div v-if="getItem.deposit">
                        <v-layout d-flex align-center>
                            <v-icon class="mr-1">
                                mdi-cash-lock
                            </v-icon>
                            <div class="subtitle-2 text--secondary">{{$t('shop.deposit')}}: {{getItem.deposit | currency}}</div>
                        </v-layout>
                    </div>
                    <div v-if="getItem.listenpreis">
                        <v-layout d-flex align-center>
                            <v-icon class="mr-1">
                                mdi-cash
                            </v-icon>
                            <div class="subtitle-2 text--secondary">{{$t('shop.listenpreis')}}: {{getItem.listenpreis.replace(',','.') | currency}}</div>
                        </v-layout>
                    </div>
                    <div v-if="getItem.listenpreis">
                        <v-layout d-flex align-center>
                            <v-icon class="mr-1">
                              mdi-cash-check
                            </v-icon>
                            <div class="subtitle-2 text--secondary">{{$t('shop.rabatt')}}: {{ (((getItem.listenpreis.replace(',','.') - price)/getItem.listenpreis.replace(',','.'))*100).toFixed(2)}} %</div>
                        </v-layout>
                    </div>
                    <v-spacer/>
                </v-layout>
            </v-layout>

          <v-layout column d-flex class="mx-4" style="min-width: 12em; max-width: 12em;">

            <v-layout row wrap>
              <v-layout d-flex flex-column>
                <div class="caption text-right">{{$t('shop.stocks.internal')}}: {{getItem.stocks.internal}}</div>
                <div class="caption text-right">{{$t('shop.stocks.external')}}: {{getItem.stocks.external}}</div>
              </v-layout>
              <div class="px-1">
                <v-icon color="grey" v-if="!getItem.ids.webiscoId">
                  mdi-circle-off-outline
                </v-icon>
                <v-icon color="success" v-else-if="getItem.stocks.internal >= 1">
                  mdi-circle-slice-8
                </v-icon>
                <v-icon color="warning" v-else-if="getItem.stocks.external >= 1">
                  mdi-circle-slice-4
                </v-icon>
                <v-icon color="error" v-else-if="getItem.stocks.internal === 0 && getItem.stocks.external === 0">
                  mdi-circle-slice-2
                </v-icon>
              </div>
              <v-btn color="primary" elevation="0" tile @click="availability" :loading="loadingAvailability" :disabled="!inCart">
                <v-icon>
                  mdi-progress-download
                </v-icon>
              </v-btn>

            </v-layout>
            <v-layout row wrap justify-end>
              <v-btn elevation="0" tile @click="OEMInfo" :disabled="!getItem.OEMInfo.length">
                <v-icon>
                  mdi-car
                </v-icon>
              </v-btn>
            </v-layout>

            <v-layout row wrap align-end justify-end>
              <div v-if="getItem.prices.length && this.$store.state.LoginStore.currencyValue && this.secondCurrency" class="mr-2">
                        <span class="text--secondary caption">
                            {{$t('article.price.perpiece')}}
                        </span>
                <span class="subtitle-1">
                            {{ secondCurrency }}
                        </span>
                <span class="text--secondary caption">
                            {{$t('shop.netto')}}
                        </span>
              </div>
            </v-layout>
            <v-layout row wrap align-end justify-end>
              <div class="headline" v-if="!getItem.prices.length">{{$t('shop.noprice')}}</div>
              <div v-if="getItem.prices.length" class="mr-2">
                        <span class="text--secondary caption">
                            {{$t('article.price.perpiece')}}
                        </span>
                <span class="headline">
                            {{price | currency}}
                        </span>
                <span class="text--secondary caption">
                            {{$t('shop.netto')}}
                        </span>
              </div>
            </v-layout>

            <v-layout row wrap align-end justify-end>
                <v-btn color="primary" elevation="0" tile @click="addItem" v-show="!inCart">
                  <v-icon>
                    mdi-cart
                  </v-icon>
                </v-btn>
              <v-layout class="my-1" v-show="inCart">
                <v-text-field v-if="cartItem" solo flat class="v-btn v-btn--tile theme--light elevation-0 v-size--default primary ma-0 centered-input" :class="{buyDelay: buyDelay}" dense prepend-icon="mdi-minus" append-outer-icon="mdi-plus" @click:prepend="decrease" @click:append-outer="increase" v-model.number="amount" hide-details/>
                <v-btn color="error" elevation="0" tile @click="removeItem">
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </v-layout>
            </v-layout>
          </v-layout>
        </v-layout>
      <oryl-shop-oeminfo v-model="showOem" v-if="showOem"></oryl-shop-oeminfo>
    </v-card>
</template>

<script>

import VueGallerySlideshow from 'vue-gallery-slideshow'
import OrylShopOeminfo from "@/components/OrylShopOEMInfo";

    export default {
        components: {VueGallerySlideshow, OrylShopOeminfo},
        name: "OrylShopResult",
        props: ['item'],
        data: function () {
            return {
                options: {
                  defaultWidth: '100%',
                  defaultHeight: '100%',
                  thumbnailStyle: {
                    backgroundSize: 'cover'
                  },
                  // more previewer options here
                },
                style: {
                  width: '100%',
                  // more styles here
                },
                open: false,
                loadingAvailability: false,
                index: null,
                showOem: false
            }
        },
        computed: {
          secondCurrency: function () {
            console.log('currency Value:');
            console.log(this.$store.state.LoginStore.currencyValue);
            if (this.$store.state.LoginStore.currencyValue === false) {
              this.$store.dispatch('LoginStore/switchCurrency', {code: this.$store.state.LoginStore.currency});
            }
            console.log('price');
            console.log(this.price);
            if (this.$store.state.LoginStore.currencyValue) {
              return this.formatPrice(this.price * this.$store.state.LoginStore.currencyValue) + ' ' + this.$store.state.LoginStore.currency;
            }
            return false;
          },
            artikelNummer: function () {
              if (this.getItem.data.no !== '0') {
                return this.getItem.data.no;
              } else {
                return this.getItem.ids.tecdocId;
              }
            },
            inCart: function () {
                return this.cartItem !== undefined;
            },
            cartItem: function () {
                return this.$store.getters['CartStore/findItem'](this.item);
            },
            buyDelay: function () {
                return this.$store.getters['CartStore/buyDelay'](this.item);
            },
            price: function () {
                return this.$store.getters['CartStore/price'](this.item);
            },
            getItem: function () {
                let cartItem = this.$store.getters['CartStore/findItem'](this.item);
                if (cartItem !== undefined) {
                    return cartItem.article;
                }

                return this.item;
            },
            amount: {
                get: function () {
                  if (typeof(this.cartItem) === 'undefined') return 0;
                    return this.cartItem.amount;
                },
                set: function (value) {
                    this.$store.dispatch('CartStore/setAmount', {item: this.item, amount: value});
                }
            }
        },
        methods: {
            OEMInfo: function () {
              this.showOem = this.getItem.OEMInfo;
            },
            onClick(i) {
              this.index = i;
            },
            formatPrice(value) {
              let val = (value/1).toFixed(2);
              return val.toString();
            },
            addItem: function () {
                this.$store.dispatch('CartStore/addItem', this.item);
            },
            removeItem: function () {
                this.$store.dispatch('CartStore/removeItem', this.item);
            },
            increase: function () {
                this.$store.dispatch('CartStore/increase', this.item);
            },
            decrease: function () {
                this.$store.dispatch('CartStore/decrease', this.item);
            },
            availability: function () {
                this.loadingAvailability = true;
                this.$store.dispatch('ShopStore/checkAvailability', this.cartItem)
                    .then((response) => {
                        this.$store.commit('CartStore/updateItem', response[0]);
                    })
                    .finally(() => {
                        this.loadingAvailability = false;
                    });
            }
        }
    }
</script>

<style scoped>
    .buyDelay >>> .v-text-field__slot input {
        color: orangered;
    }

    .centered-input >>> .v-text-field__slot input {
        text-align: center !important;
    }
</style>
