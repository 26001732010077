<template>
    <v-banner sticky app class="orylsticky">
        <slot/>
    </v-banner>
</template>

<script>
    export default {
        name: "OrylSticky"
    }
</script>

<style>
    .orylsticky > .v-banner__wrapper {
        padding: unset !important;
        border-bottom: unset !important;
    }

    .orylsticky > .v-banner__wrapper > .v-banner__content {
        padding: unset !important;
        overflow: unset !important;
    }

    .orylsticky > .v-banner__wrapper > .v-banner__content > .v-banner__text {
        flex: 1;
    }
</style>
