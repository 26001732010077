<template>
    <v-btn fab color="indigo" dark left fixed bottom class="" @click="scrollToTop">
        <v-icon>
            mdi-chevron-up
        </v-icon>
    </v-btn>
</template>

<script>
    export default {
        name: "OrylToTop",
        data: () => ({
            visible: false
        }),
        methods: {
            scrollToTop: function () {
                this.$vuetify.goTo(0);
            }
        }
    }
</script>

<style scoped>

</style>
