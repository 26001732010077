<template>
    <v-dialog :value="value" persistent width="50%">
        <v-card>
            <v-toolbar flat dense>
                <v-layout d-flex justify-end>
                    <v-btn icon @click="close">
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn>
                </v-layout>
            </v-toolbar>
            <div class="pa-3">
                <v-data-table :items="value" :headers="headers"
                              :footer-props="{
                  'items-per-page-options': [15, 25, 50, 100]
                }"
                :items-per-page="25">
                </v-data-table>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>

    export default {
        name: "OrylShopOeminfo",
        props: {
            value: {
                default: [],
            }
        },
        computed: {
          headers () {
            return [
              {
                text: 'Artikelnummer',
                align: 'start',
                sortable: true,
                value: 'articleNumber',
              },
              { text: 'Hersteller', value: 'mfrName' }
            ]
          }
        },
        methods: {
              close: function () {
                  this.$emit('input', false);
                  this.item = null;
                  this.loaded = false;
            },
        },
    }
</script>

<style scoped>

</style>
